<template>
  <div style="padding-top: 2rem" class="info-container mx-auto">
    <div class="text-end mb-4">
      <button class="btn-inline btn" @click="openAssignmentEditor" v-if="userData.roleId == 2"
      :disabled="editingNewAnnouncements == true">
        <i class="align-middle material-icons md-32 md-light">add_circle_outline</i>
      </button>
    </div>
    <div v-show="editingNewAnnouncements">
      <div id="editor"></div>
      <div class="my-2 text-end">
        <button type="button" class="btn btn-inline btn-primary me-1"
        @click="postAnnouncement">
          Post
        </button>
        <button type="button" class="btn btn-secondary btn-inline"
        @click="closeAssignmentEditor">
          Cancel
        </button>
      </div>
    </div>
    <div id="announcement-container" class="mx-auto p-0 mt-3">
      <div class="card-body">
        <div>
          <div v-if="announcements.length == 0" class="text-center mb-4">
            <em>There are no announcements</em>
          </div>
          <div class="card announcement mb-3" v-for="a, idx in announcements" :key="idx">
            <div class="card-header d-flex justify-content-between align-items-baseline">
              <span>
                <span class="material-icons mr-1 align-middle">account_circle</span>
                Posted by {{ a.author }}
              </span>
              <span>
                  {{ a.created }}
                  <button type="button" class="btn btn-link text-danger py-0 pr-0"
                  @click="deleteAnnouncement(a.id)"
                  v-if="userData.roleId == 2">
                    <i class="material-icons">cancel</i>
                  </button>
                </span>
            </div>
            <div class="card-body">
              <div class="collapse" :id="'announcement_'+idx" v-html="a.content"></div>
              <div class="text-center collapse-control">
                <a role="button" class="collapsed text-decoration-none text-dark"
                data-bs-toggle="collapse" :href="'#' + 'announcement_' +idx"></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import Swal from 'sweetalert2';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import InlineEditor from '../ckeditor';

export default {
  name: 'CourseDashboard',
  components: {
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
  },
  data() {
    return {
      editingNewAnnouncements: false,
      announcementEditor: null,
      announcements: [],
      announcementFocus: null,
      classId: null,
    };
  },
  methods: {
    openAssignmentEditor() {
      InlineEditor
        .create(document.querySelector('#editor'), {
          toolbar: {
            items: [
              'heading', '|',
              'alignment', 'fontFamily', 'fontColor', 'fontBackgroundColor', 'fontSize', '|',
              'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|',
              'indent', 'outdent', '|',
              'horizontalLine', 'blockQuote', 'insertTable', '|',
              'undo', 'redo',
            ],
          },
          language: 'en',
          table: {
            contentToolbar: [
              'tableColumn',
              'tableRow',
              'mergeTableCells',
            ],
          },
          licenseKey: '',
        })
        .then((newEditor) => {
          this.announcementEditor = newEditor;
        });
      this.editingNewAnnouncements = true;
    },
    getAnnouncements() {
      this.$store.commit('setLoadScreen', { status: true });
      axios.get(`/api/course/${this.classId}/announcements`)
        .then((response) => {
          this.announcements = response.data;
          this.$store.commit('setLoadScreen', { status: false });
        });
    },
    postAnnouncement() {
      this.$store.commit('setLoadScreen', { status: true });
      const content = this.announcementEditor.getData().replace(/"/g, '"').replace(/'/g, "'");

      axios.post('/api/course/create_announcement', {
        course: this.classId,
        content: JSON.stringify(content),
        user: this.userData.id,
      })
        .then((response) => {
          this.announcements = response.data;
          this.closeAssignmentEditor();
          this.$store.commit('setLoadScreen', { status: false });
        });
    },
    closeAssignmentEditor() {
      this.announcementEditor.setData('');
      this.announcementEditor.destroy();
      this.editingNewAnnouncements = false;
    },
    deleteAnnouncement(id) {
      Swal.fire({
        title: 'Are you sure you want to delete this announcement?',
        text: 'This action cannot be undone.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.commit('setLoadScreen', { status: true });
          axios.post('/api/course/delete_announcement', {
            course: this.classId,
            announcement: id,
          })
            .then((response) => {
              this.announcements = response.data;
              this.$store.commit('setLoadScreen', { status: false });
              Swal.fire(
                'Deleted!',
                'The announcement has been deleted.',
                'success',
              );
            })
            .catch((err) => {
              this.$store.commit('setLoadScreen', { status: false });
              Swal.fire(
                'Oops!',
                'The announcement could not be deleted.',
                'error',
              );
              console.log(err);
            });
        }
      });
    },
  },
  mounted() {
    this.classId = this.$route.params.cid;
    this.getAnnouncements();
  },
};
</script>
<style scoped>
#editor {
  box-shadow: inset 0 0 5px #cccccc;
}

#announcement-container {
  box-shadow: inset 0 0 10px #cccccc;
  max-height: 75vh;
  overflow-y: auto;
}

.announcement .collapse, .announcement .collapsing {
  height: 2rem;
}

.announcement .collapse {
  display: block;
  overflow: hidden;
}

.announcement .collapse.show {
  height: auto;
}

.collapse-control a.collapsed:after {
  content: '▼ Expand';
}

.collapse-control a:not(.collapsed):after {
  content: '▲ Collapse';
}
</style>
